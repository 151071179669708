























































import { defineComponent, computed } from "@vue/composition-api";
import eventBus from "@monorepo/utils/src/eventBus";
import { environmentVariables } from "@monorepo/utils/src/variables/environmentVariables";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import TableFileList from "./TableFileList.vue";
import useTableCells from "@monorepo/utils/src/composables/useTableCells";

export enum ApproveStatuses {
  APPROVED = "APPROVED",
  ON_APPROVING = "IN_PROGRESS",
}

export default defineComponent({
  name: "ApproveTable",
  components: {
    TableFileList,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    isHideEditButtons: {
      type: Boolean,
    },
    isMainPerson: {
      type: Boolean,
    },
    tableHeaders: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ApproveStatuses,
      successIcon: `${environmentVariables.BASE_URL}assets/images/tk-journal/successIcon.svg`,
      errorIcon: `${environmentVariables.BASE_URL}assets/images/tk-journal/errorIcon.svg`,
      pendingIcon: `${environmentVariables.BASE_URL}assets/images/tk-journal/pendingIcon.svg`,
    };
  },
  computed: {
    resultData(): Record<string, string>[] {
      return this.items as Record<string, string>[];
    },
    statusIcon() {
      return (status: string) => {
        switch (status) {
          case ApproveStatuses.APPROVED:
            return this.successIcon;
          case ApproveStatuses.ON_APPROVING:
            return this.pendingIcon;
          default:
            return this.errorIcon;
        }
      };
    },
  },
  methods: {
    onScrollCb(e: Event) {
      const element = document.getElementById("doc-table");
      if (element) {
        element.scrollLeft = (e.target as HTMLElement)?.scrollLeft;
      }
    },
  },
  mounted() {
    (this as unknown as { getTableWidth: () => void }).getTableWidth();
  },
  beforeMount() {
    eventBus.$on(MODAL_EVENT_BUS_ACTIONS.TOGGLE_VIEW_MODE, this.getTableWidth as () => void);
  },
  beforeDestroy() {
    eventBus.$off(MODAL_EVENT_BUS_ACTIONS.TOGGLE_VIEW_MODE, this.getTableWidth as () => void);
  },
  setup(props, { root }) {
    const tableHeaders = computed(() => props.tableHeaders);
    const { headers, getTableWidth } = useTableCells(root, "", tableHeaders, 1120, true);
    return {
      headers,
      getTableWidth,
    };
  },
});
